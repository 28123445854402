<template>
    <div v-if="loader">
        Loading...
    </div>
    <div v-if="! loader">
        <CourierGlobalHeader :global_err="global_err" />
        <div v-if="global_err == ''">
            <div class="package_section package_validation_section" v-if = "display_box_screen == true">
                <div class="container">
                    <div class="table_wrap">
                        <div class="cell_wrap">
                            <div class="empty_package">
                                <img src="images/thankyou_page_box.png" alt="Package Empty" style="width: 30%;">
                                <p>{{box_number}} מספר simpo  אנא אתרו את תיבת  </p>
                                <a href="#" class="comman_anchor" @click = "here">אני מול התיבה</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="package_section package_validation_section" v-if = "display_im_here == true">
                <div class="container">
                    <div class="table_wrap">
                        <div class="cell_wrap">
                            <img src="images/thankyou_page_box.png" alt="Package Empty" style="width: 30%; ">
                            <a href="#" class="comman_anchor" @click = "point_open_box_change_status">פתיחת תיבה
                                <!--<div class="open_box_circel" style="width: 30%; ">
                                    <h2>פתיחת תיבה</h2>
                                </div>-->
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="package_section package_change_or_confirm_size_section" v-if = "display_change_or_confirm_size == true">
                <div class="container">
                    <div class="table_wrap">
                        <div class="cell_wrap">
                            <img src="images/thankyou_page_box.png" alt="Package Empty" style="width: 30%;">
                            <h2>החבילה גדולה מדי? </h2>
                            <a href="#" class="comman_anchor" @click ="point_change_box_size" >שינוי גודל תיבה</a>
                            <a href="#" class="comman_anchor" @click ="point_continue_no_box_size_change" >המשך ללא שינוי</a>
                            <a href="#" class="comman_anchor" @click ="point_no_box_size_fits" >אין גודל מתאים</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="package_section package_change_or_confirm_size_section" 
                v-if = "display_change_box_size_confirm_close_org == true"><!--package_change_box_size_confirm_close_org_section-->
                <div class="container">
                    <div class="table_wrap">
                        <div class="cell_wrap">
                            <img src="images/thankyou_page_box.png" alt="Package Empty" style="width: 30%;">
                            <h2>אנא סגור את התיבה ולחץ אישור</h2>
                            <div class="courier_point_new_package_e_form-group">
                                <a href="#" class="comman_anchor" @click ="point_confirm_org_close" >אישור</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="package_section package_change_or_confirm_size_section" 
                v-if = "display_select_new_larger_size == true"><!--select_new_larger_size_section-->
                <div class="container">
                    <div class="table_wrap">
                        <div class="cell_wrap">
                            <img src="images/thankyou_page_box.png" alt="Package Empty" style="width: 30%;">
                           <h2> בחירת גודל תיבה גדולה יותר</h2>
                           <form @submit="point_confirm_new_size" class="courier_point_new_package_e_form">
                                <p class="error_text" style="display: none">אופס, שכחת משהו</p>
                                <p class="error_text">{{ errs }}</p>
                                
                                <div class="courier_point_new_package_e_form-group">
                                    <label>בחירת גודל תיבה</label>
                                    <select id="sizeList" name="" class="form-control" v-model="size">
                                        <option v-for="box in free_boxes" :key="box.size" :value="box.size">
                                        {{ getSizeLongDescription(box.size) }}
                                        </option>
                                    </select>
                                </div>
                                <input type="submit" class="comman_anchor" value="הוספה" />
                            </form>

                            <!-- TO OD ADD BOX SIZE HERE WIT LARGER SIZES and transition back to box screen-->
                            <div class="courier_point_new_package_e_form-group">
                            <a href="#" class="comman_anchor" @click ="point_confirm_new_size" >אישור</a>
                        </div>
                        </div>
                    </div>
                </div>
            </div>




            <div class="package_section receving_packages_close_box"  v-if = "close_screen == true">
                <div class="container">
                    <div class="table_wrap">
                        <div class="cell_wrap">
                            <div class="empty_package">
                                <img src="images/thankyou_page_box.png" alt="Thank You">
                                
                                <p>אם גודל תיבה {{box_number}} אינו מספיק וברצונך לבחור תיבה אחרת, אנא לחץ בטל וסגור את התיבה והמערכת תאפשר לך לבחור תיבה חלופית</p>
                                <p>שימו לב! לאחר סגירת התיבה לא ניתן לפתוח אותה בשנית</p>
                                <a href="#" class="comman_anchor" @click ="point_cancel_and_close_box" >סגרתי את התיבה (מעבר לבחירת גודל תיבה חדש)</a>
                                                                
                                <!--<p>אנא סגרו את תיבה מספר {{box_number}}</p>-->
                                <p>אנא סגרו היטב את תיבה מספר {{box_number}}</p>
                                <p>שימו לב!!! לאחר סגירת התיבה לא ניתן לפתוח אותה שוב</p>
                                <a href="#" class="comman_anchor" @click ="point_close_box" >שליחת הודעה ללקוח</a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import BackendModel from "../../models/BackendModel";
    import CourierGlobalHeader from "./CourierGlobalHeader";
    import('./courier/css/style.css')

    export default {
      name: 'CourierPointNewPackagesValidationE',
      components:{
        CourierGlobalHeader,
      },
      data() {
        return {
            loader: false,
            view_data : [],
            
            display_box_screen : true,
            display_im_here : false,
            display_change_or_confirm_size: false,
            
            display_change_box_size_confirm_close_org: false,
            display_close_box_and_send_customer_message: false,
            display_thanks_and_sorry: false,

            display_select_new_larger_size: false,

            close_screen : false,
            closed_box_finish : false,
            cancelled_and_closed_box : false,

            free_boxes :[],

            box_id : 0,
            box_number : 0,
            package_number : '',
            phone_number : '',
            
            errs:'',
            global_err : ''
        }
      },
       async mounted () {
        this.loader = true
        this.box_id = this.$route.query.box_id
        this.box_number = this.$route.query.box_number
        this.point_id = this.$route.query.point_id
        this.package_number = this.$route.query.package_number
        this.phone_number = this.$route.query.phone_number

        this.free_boxes = this.view_data.free_boxes

        this.loader = false  
        this.errs = ''
        //this.loader_prop = false
       },

       methods :{
            here(){
               this.display_im_here = true
               this.display_box_screen = false
            },
            async point_open_box_change_status(){
                this.loader_prop = true
 //               let backendModel = new BackendModel()             
//                let res = await backendModel.courierE_Request('/Api/service/backoffice/courier_point_open_box_change_status', 
//                                                                {"box_id": this.box_id});

 //               if(res.data.res == "OK"){
                    this.loader_prop = false
                    this.display_im_here = false
                    
                    //TODO add handling if already done this not possible to change then go to final scree
                    this.display_change_or_confirm_size = true
                    
                    //this.close_screen =true
 //               }
           },
           point_change_box_size (){
                this.display_change_or_confirm_size = false
                this.display_change_box_size_confirm_close_org = true
            },
            point_continue_no_box_size_change (){
                this.display_change_or_confirm_size = false
                this.display_close_box_and_send_customer_message = true
            },
            point_no_box_size_fits (){
                this.display_change_or_confirm_size = false
                this.point_no_box_size_fits = true
            },
            
            point_confirm_org_close (){
                this.display_change_box_size_confirm_close_org = false
                this.display_select_new_larger_size = true
                //TODO NEED ALSO TO UPDATE BOX STATUS TO CLEAN EMPTY MOVE TO SELECT NRE SIZE
            },
            point_confirm_new_size (){
                this.display_select_new_larger_size = false
                this.display_box_screen = true
                //TODO NEED TODO a new find box for new size before transition
                //Set something to know we did one change
            },
            getSizeLongDescription(size) {
                const sizeMapping = {
                    's': 'קטן (45x40)',
                    'm': 'בינוני (24.5x40)',
                    'l': 'גדול (11.5x40)',
                    'j': 'ג׳מבו (90x90)',
                };
                return sizeMapping[size] || size;
            },





           async point_close_box(){
                this.loader_prop = true    
                let backendModel = new BackendModel()
                let res = await backendModel.courierE_Request('/Api/service/backoffice/courier_point_close_box', 
                                                                {"box_id": this.box_id});
                if(res.data.res == "OK"){
                    this.loader_prop = false
                    this.close_screen = false
                    //no new route here jus change to new state
                    //await this.$router.push({ path: '/point/point_packages'}) //change to goddbye screen
                }
           },
           async point_cancel_and_close_box(){
                this.loader_prop = true    
                let backendModel = new BackendModel()
                let res = await backendModel.courierE_Request('/Api/service/backoffice/courier_point_cancel_and_close_box', 
                                                                {"box_id": this.box_id});
                if(res.data.res == "OK"){
                    this.loader_prop = false
                    this.close_screen = false
                    //New rout or display something?
                    //await this.$router.push({ path: '/point/point_packages'})//change to select new box screen or state
                }
           },

    }
}
</script>

<style>
@import 'courier/css/style.css';
</style>